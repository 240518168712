import React, { useEffect, useState } from 'react';
import './App.css';
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Layout, Row, Timeline, Typography, message } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setEmail, setLoading, setMembresia, setName, setPrice, setUrl } from './context/custumerSlice';
import moment from 'moment';
import axios from 'axios';

import { FaClock } from 'react-icons/fa';




function App() {
    const [currentStep, setCurrentStep] = useState(1);

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    useEffect(() => {
    }, [])

    const RenderCurrentStep = () => {
        switch (currentStep) {
            case 1:
                return <Step1 onNextStep={handleNextStep} />;
            case 2:
                return <Step2 onNextStep={handleNextStep} />;
            case 3:
                return <Step3 onNextStep={handleNextStep} />;
            case 4:
                return <Step4 onNextStep={handleNextStep} />;
            case 5:
                return <Step5 />;
            default:
                return null;
        }
    };

    return (
        <>
            <RenderCurrentStep />
        </>
    );
}


const Step1 = ({ onNextStep, onClose }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const onFinishEmail = (values) => {
        try {
            dispatch(setEmail(values.email));
            dispatch(setName(values.name));
            onNextStep();
        } catch (error) {
            console.log('🚀 ~ file: EmailModal.js:22 ~ onFinishEmail ~ error:', error);
            message.error('Ocurrió un error al enviar el correo electrónico');
        }
    };

    const handleBannerClick = (event) => {
        event.preventDefault();
        window.open('https://gigstack.pro/', '_blank');
    };

    return (
        <div
            style={{
                backgroundColor: '#0E0C16',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div style={{ marginBottom: '20px' }}>
                <a
                    href="https://gigstack.pro/"
                    onClick={handleBannerClick}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                >
                    <p style={{ margin: 0, height: 'auto', fontSize: '20px', color: 'white', marginBottom: '10px' }} className="russo">
                        Demo |
                    </p>
                    <p style={{ margin: 0, height: 'auto', fontSize: '20px', marginBottom: '10px', marginLeft: '4px' }} className="russo primary">
                        {' '}
                        gigstack pro
                    </p>
                </a>
            </div>
            <Card style={{ width: '90%', maxWidth: '700px' }}>
                <Form form={form} onFinish={onFinishEmail}>
                    <div className="d-flex flex-column align-items-center">
                        <Typography.Title level={3} style={{ textAlign: 'center', marginTop: '20px', marginBottom: '10px' }}>
                            ¡Vamos a hacer magia✨!
                        </Typography.Title>
                        <Typography.Text style={{ textAlign: 'center', marginBottom: '15px', lineHeight: '1.5', fontSize: '16px' }}>
                            Gracias por tu interés en gigstack, antes de comenzar con el <strong>DEMO</strong>, ¿podrías proporcionarnos tus datos?
                        </Typography.Text>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Form.Item
                                name="name"
                                label="Nombre"
                                labelCol={{ span: 24, offset: 0 }}
                                rules={[{ required: true, message: 'Por favor ingresa tu nombre' }]}
                                style={{ marginBottom: '10px', width: window.innerWidth < 850 ? '90%' : '70%' }}
                            >
                                <Input style={{ textAlign: 'center' }} placeholder="Nombre" />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label="Correo electrónico"
                                labelCol={{ span: 24, offset: 0 }}
                                rules={[{ required: true, message: 'Por favor ingresa tu correo electrónico', type: 'email' }]}
                                style={{ width: window.innerWidth < 850 ? '90%' : '70%' }}
                            >
                                <Input style={{ textAlign: 'center' }} placeholder="Correo electrónico" />
                            </Form.Item>
                        </div>
                        <Button className="" type="primary" style={{ alignSelf: 'center' }} htmlType="submit">
                            Comenzar
                        </Button>
                    </div>
                </Form>
            </Card>
        </div>
    );
};


const Step2 = ({ onNextStep }) => {
    const modalWidth = window.innerWidth <= 800 ? '100%' : '50%';

    return (
        <div
            style={{
                backgroundColor: '#0E0C16',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div style={{ marginBottom: '20px' }}>
                <a href="https://gigstack.pro/" target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                    <p style={{ margin: 0, height: 'auto', fontSize: '20px', color: 'white', marginBottom: '10px' }} className="russo">
                        Demo |
                    </p>
                    <p style={{ margin: 0, height: 'auto', fontSize: '20px', marginBottom: '10px', marginLeft: '4px' }} className="russo primary">
                        gigstack pro
                    </p>
                </a>
            </div>
            <Card style={{ width: '90%', maxWidth: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
                <div className="d-flex flex-column align-items-center" style={{ marginBottom: '20px' }}>
                    <Typography.Title level={3} style={{ textAlign: 'center' }}>
                        ¡Bienvenido a la plataforma <br />
                        <span className="fontMagic">HOW-ARTS!</span>
                    </Typography.Title>
                </div>
                <Row className="d-flex flex-column align-items-center" type="secondary" style={{ marginBottom: '20px', color: '#757575' }}>
                    <Typography.Text style={{ textAlign: 'center', lineHeight: '1.5', fontSize: '16px' }}>
                        Instrucciones:
                    </Typography.Text>
                    <ul style={{ textAlign: 'left', listStylePosition: 'inside' }}>
                        <li>Comprarás una suscripción a cursos de magia ✨</li>
                        <li>Te llegará un recibo de venta a tu correo 🧙🏻‍♀️</li>
                        <li>Podrás realizar tu autofactura 🤯</li>
                    </ul>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <Button className="" type="primary" onClick={onNextStep}>
                        Comenzar ¡ALOHOMORA!
                    </Button>
                </div>
            </Card>
        </div>
    );
};
const Step3 = ({ onNextStep }) => {
    const dispatch = useDispatch();

    useEffect(() => { }, []);

    const handleClick = (value, member) => {
        onNextStep();
        dispatch(setPrice(value));
        dispatch(setMembresia(member));
    };

    const handleBannerClick = (event) => {
        event.preventDefault();
        window.open('https://gigstack.pro/', '_blank');
    };

    const UncheckedItem = ({ text }) => {
        return (
            <Row style={{ width: '100%', marginTop: '10px' }}>
                <CloseOutlined />
                <Typography.Text style={{ marginLeft: '10px', flex: 1, textDecorationLine: 'line-through' }} type="secondary">
                    {text}
                </Typography.Text>
            </Row>
        );
    };

    const CheckedItem = ({ text, strong, color }) => {
        return (
            <Row style={{ width: '100%', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                <div
                    style={{
                        width: '15px',
                        height: '15px',
                        borderRadius: '50%',
                        backgroundColor: '#8666FF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        verticalAlign: 'middle',
                    }}
                >
                    <CheckOutlined style={{ color: 'white', fontSize: '12px' }} />
                </div>
                <Typography.Text
                    style={{
                        marginLeft: '10px',
                        flex: 1,
                        fontWeight: strong ? 'bold' : 'normal',
                        color: color,
                        verticalAlign: 'middle',
                    }}
                >
                    {text}
                </Typography.Text>
            </Row>
        );
    };

    const PricingCard = ({ title, description, price, onClick, items }) => {
        let textColor = 'white'; // default
        let itemColor = '#DDDDDD';
        if (title === 'Raven') {
            textColor = '#94FFF2';
        } else if (title === 'Gryfi') {
            textColor = '#FF9494';
        } else if (title === 'Slythy') {
            textColor = '#94FFB2';
        } else if (title === 'Huffs') {
            textColor = '#FFF494';
        }
        return (
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                <div
                    style={{
                        padding: '30px',
                        border: '1px solid white',
                        backgroundColor: '#0E0C16',
                        marginBottom: '20px',
                        width: '100%',
                        minHeight: '460px'
                    }}
                    className="d-flex flex-column pricingCard"
                >
                    <Typography.Title style={{ marginTop: '10px', color: textColor }} level={1}>
                        {title}
                    </Typography.Title>
                    <Typography.Text
                        type="secondary"
                        style={{ textAlign: 'center', minHeight: '32.4px', color: '#DDDDDD' }}
                        className=""
                    >
                        {description}
                    </Typography.Text>
                    <Typography.Title
                        style={{ marginTop: '10px', marginBottom: 0, paddingBottom: 0, color: '#DDDDDD' }}
                        level={2}
                    >
                        ${price}
                    </Typography.Title>

                    <Button style={{ width: '100%', marginTop: '30px', backgroundColor: '#8666FF' }} onClick={onClick}>
                        Comprar
                    </Button>
                    <Typography.Title
                        style={{ marginTop: '15px', marginBottom: 0, paddingBottom: 0, width: '100%', textAlign: 'start', color: '#DDDDDD' }}
                        level={4}
                    ></Typography.Title>
                    {items.map((item, index) => (
                        <CheckedItem key={index} text={item} strong={false} color={itemColor} />
                    ))}
                </div>
            </Col>
        );
    };

    return (
        <>
            <Layout
                style={{
                    backgroundColor: '#0E0C16',
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{ marginBottom: '20px', position: 'absolute', top: 0, right: 0, marginTop: '15px', marginRight: '20px' }}
                >
                    <a
                        href="https://gigstack.pro/"
                        onClick={handleBannerClick}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                    >
                        <p style={{ margin: 0, height: 'auto', fontSize: '20px', color: 'white', marginBottom: '10px' }} className="russo">
                            Demo |
                        </p>
                        <p style={{ margin: 0, height: 'auto', fontSize: '20px', marginBottom: '10px', marginLeft: '4px' }} className="russo primary">
                            {' '}
                            gigstack pro
                        </p>
                    </a>
                </div>
                <Layout.Content
                    style={{
                        minHeight: window.innerHeight - 74,
                        width: '100%',
                        padding: '20px 5%',
                        marginTop: '70px',
                        backgroundColor: '#0E0C16',
                    }}
                    className="container"
                >
                    <Row justify="center">
                        <Typography.Title style={{ textAlign: 'center', fontSize: '30px', color: 'white' }} className="fontMagic">
                            Suscríbete al mundo mágico:
                        </Typography.Title>
                    </Row>
                    <Row gutter={[24, 24]} justify="center" style={{ paddingTop: '50px' }}>
                        <PricingCard
                            title="Raven"
                            description="Un plan lleno de sabiduría e intelectualidad"
                            price={3}
                            onClick={() => handleClick(3, 'Raven')}
                            items={[
                                'Poder y misterio entrelazados en Raven.',
                                'Descubre la oscuridad que habita en Raven.',
                                'Sumérgete en la elegancia nocturna de Raven.',
                            ]}
                        />
                        <PricingCard
                            title="Gryfi"
                            description="Un plan muy valiente y atrevido"
                            price={5}
                            onClick={() => handleClick(5, 'Gryfi')}
                            items={[
                                'Explosión de colores en Grafy.',
                                'Creatividad en movimiento: bienvenido a Grafy.',
                                'Imágenes que cobran vida en Grafy.',
                            ]}
                        />
                        <PricingCard
                            title="Slythy"
                            description="Un plan un poco tenebroso y ambicioso"
                            price={7}
                            onClick={() => handleClick(7, 'Slythy')}
                            items={[
                                'Sigiloso y astuto: bienvenido a Slythy.',
                                'Descubre la esencia reptil de Slythy.',
                                'Una serpiente encantadora: bienvenido a Slythy.',
                            ]}
                        />
                        <PricingCard
                            title="Huffs"
                            description="Un plan leal, Justo y optimista"
                            price={9}
                            onClick={() => handleClick(9, 'Huffs')}
                            items={[
                                'Lealtad y valentía definen a Huffs.',
                                'Descubre el espíritu audaz de Huffs.',
                                'Un hogar acogedor en Huffs.',
                            ]}
                        />
                    </Row>
                </Layout.Content>
            </Layout>
        </>
    );
};

function Step4({ onNextStep }) {

    const { email, price, loading, name, membresia } = useSelector(state => state.custumerData)
    const dispatch = useDispatch()

    const handleBannerClick = (event) => {
        event.preventDefault();
        window.open('https://gigstack.pro/', '_blank');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            dispatch(setLoading(true))
            const resp = await axios.post(`https://us-central1-gigstackpro.cloudfunctions.net/testReceiptForMicrosite`, {
                client: { name, email, legal_name: 'PUBLICO EN GENERAL', legalName: 'PUBLICO EN GENERAL', rfc: 'XAXX010101000', address: { zip: '10200', country: 'MEX' }, tax_system: '616', usage: 'S01' },
                items: [{
                    description: membresia,
                    discount: 0,
                    id: 'Plan',
                    name: membresia,
                    paymentType: { label: 'tarifa fija', value: 'fixed' },
                    product_key: '01010101',
                    quantity: 1,
                    taxes: [{ factor: 'Tasa', inclusive: true, rate: '0.16', type: 'IVA', whitholding: false }],
                    total: price,
                    unit_key: 'E48',
                    unit_name: 'servicio'
                }],
                currency: 'MXN',
                livemode: false,
                periodicity: 'month',
                payment_form: { value: "04", label: "Tarjeta de crédito" },
                exchange: 1,
                test: true,
                validDays: moment().endOf('month').valueOf(),
                validUntil: moment().endOf('month').valueOf(),
                type: "create_receipt",
                payments: [],
                team: 'team_V47BfNUOTtWXvgX',
                paymentId: null,
                owner: 'NA0aupwgVTgignhoCGih4sGFw0H2',
                demo: true
            })
            dispatch(setUrl(resp.data))
            dispatch(setLoading(false))
            onNextStep()
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false))
        }

    };

    return (

        <Layout
            style={{
                backgroundColor: '#0E0C16',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div style={{ marginBottom: '20px', position: 'absolute', top: 0, right: 0, marginTop: '15px', marginRight: '20px' }}>
                <a
                    href="https://gigstack.pro/"
                    onClick={handleBannerClick}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                >
                    <p style={{ margin: 0, height: 'auto', fontSize: '20px', color: 'white', marginBottom: '10px' }} className="russo">
                        Demo |
                    </p>
                    <p style={{ margin: 0, height: 'auto', fontSize: '20px', marginBottom: '10px', marginLeft: '4px' }} className="russo primary">
                        gigstack pro
                    </p>
                </a>
            </div>
            <Layout.Content
                style={{ minHeight: '70vh', width: '90%', maxWidth: '500px', padding: '20px', marginTop: '70px', backgroundColor: '#0E0C16' }}
                className="container"
            >
                <Row gutter={[24, 24]} justify="center" style={{ paddingTop: '50px' }}>
                    <div
                        style={{
                            padding: '30px',
                            border: '1px solid gray',
                            backgroundColor: '#0E0C16',
                            marginBottom: '20px',
                            width: '100%',
                            color: 'gray',
                            borderRadius: '5px',
                        }}
                    >
                        <Typography.Title level={3} style={{ marginBottom: '30px', color: 'gray' }}>
                            <span className="fontMagic primary">HOW-ARTS</span> <br />
                            <span className="fontMagic" style={{ color: 'white' }}>
                                Último paso para ver la magia
                            </span>{' '}
                            <br />
                            <p style={{ fontSize: '15px' }}>Para finalizar y poder ver la verdadera magia de ahorrar tiempo y dinero, saca tu dinero de Grin-gots</p>
                        </Typography.Title>
                        <div style={{ marginBottom: '20px', textAlign: 'left' }}>
                            <div style={{ marginBottom: '10px' }}>
                                <label htmlFor="card-number" style={{ display: 'block', marginBottom: '5px', textAlign: 'left', fontWeight: 'bold', color: 'gray' }}>
                                    Número de tarjeta:
                                </label>
                                <Input
                                    id="card-number"
                                    type="text"
                                    style={{
                                        border: '1px solid gray',
                                        borderRadius: '4px',
                                        padding: '10px',
                                        width: '100%',
                                        color: 'gray',
                                    }}
                                    defaultValue="4242424242424242"
                                    disabled
                                />
                            </div>
                            <Row justify="center" style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '10px' }}>
                                <div style={{ marginBottom: '0px', width: '50%' }}>
                                    <label htmlFor="card-expiry" style={{ display: 'block', marginBottom: '5px', textAlign: 'left', fontWeight: 'bold', color: 'gray' }}>
                                        Vencimiento:
                                    </label>
                                    <Input
                                        id="card-expiry"
                                        type="text"
                                        style={{
                                            border: '1px solid gray',
                                            borderRadius: '4px',
                                            padding: '10px',
                                            width: '100%',
                                            color: 'gray',
                                        }}
                                        value="12/27"
                                        disabled
                                    />
                                </div>
                                <div style={{ marginBottom: '5px', width: '50%' }}>
                                    <label htmlFor="card-cvc" style={{ display: 'block', marginBottom: '5px', textAlign: 'left', fontWeight: 'bold', color: 'gray' }}>
                                        CVC:
                                    </label>
                                    <Input
                                        id="card-cvc"
                                        type="text"
                                        style={{
                                            border: '1px solid gray',
                                            borderRadius: '4px',
                                            padding: '10px',
                                            width: '100%',
                                            color: 'gray',
                                        }}
                                        value="123"
                                        disabled
                                    />
                                </div>
                            </Row>
                            <div style={{ alignItems: 'center', display: 'flex', marginTop: '20px' }}>
                                <Button loading={loading} type="primary" style={{ margin: '0 auto', width: '100%' }} onClick={handleSubmit}>
                                    Suscribirme
                                </Button>
                            </div>
                        </div>
                    </div>
                </Row>
            </Layout.Content>
        </Layout>
    );
}

const Step5 = () => {
    const { url } = useSelector((state) => state.custumerData);
    const [step, setStep] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setStep(step + 1);
        }, 2000);
    }, [step]);

    const handleBannerClick = (event) => {
        event.preventDefault();
        window.open('https://gigstack.pro/', '_blank');
    };

    return (
        <div
            style={{
                backgroundColor: '#0E0C16',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    marginBottom: '20px',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    marginTop: '15px',
                    marginRight: '20px',
                }}
            >
                <a
                    href="https://gigstack.pro/"
                    onClick={handleBannerClick}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                >
                    <p style={{ margin: 0, height: 'auto', fontSize: '20px', color: 'white', marginBottom: '10px' }} className="russo">
                        Demo |
                    </p>
                    <p style={{ margin: 0, height: 'auto', fontSize: '20px', marginBottom: '10px', marginLeft: '4px' }} className="russo primary">
                        gigstack pro
                    </p>
                </a>
            </div>
            <Card
                style={{
                    padding: '20px',
                    width: '90%',
                    maxWidth: '600px',
                    borderRadius: 8,
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Typography.Title level={5} style={{ marginBottom: '30px', textAlign: 'center' }}>
                    Estamos haciendo mágia con la facturación ✨🧙🏻‍♀️
                </Typography.Title>
                <Timeline mode="left" style={{ width: '100%' }}>
                    {step >= 0 && (
                        <Timeline.Item
                            dot={step === 0 ? <LoadingOutlined style={{ fontSize: '15px', color: '#8666FF' }} spin /> : <CheckOutlined style={{ fontSize: '15px', color: '#8666FF' }} />}
                            style={{ textAlign: 'center' }}
                        >
                            {step === 0 ? 'Creando el recibo de venta...' : 'Recibo de venta creado'}
                        </Timeline.Item>
                    )}
                    {step >= 1 && (
                        <Timeline.Item
                            dot={step === 1 ? <LoadingOutlined style={{ fontSize: '15px', color: '#8666FF' }} spin /> : <CheckOutlined style={{ fontSize: '15px', color: '#8666FF' }} />}
                            color="red"
                            style={{ textAlign: 'center' }}
                        >
                            {step === 1 ? `Enviando el recibo de venta por correo a ${url?.receipt?.client?.email}` : `Recibo de venta enviado a ${url?.receipt?.client?.email}`}
                        </Timeline.Item>
                    )}
                    {step >= 2 && (
                        <Timeline.Item
                            dot={<FaClock style={{ fontSize: '15px', color: '#8666FF' }} />}
                            color="#6F75FF"
                            style={{ textAlign: 'center' }}
                        >
                            Esperando que realices tu autofactura
                        </Timeline.Item>
                    )}
                </Timeline>
                {step >= 2 && (
                    <div style={{ textAlign: 'center', marginTop: '30px', width: '100%' }}>
                        {/* <iframe src={url?.receipt?.url} width={'100%'} title='GigstackPortal' /> */}
                        <Button type="primary" onClick={() => window.open(`${url?.receipt?.url}`, '_blank')}>
                            Ver portal de autofactura
                        </Button>
                    </div>
                )}
            </Card>
        </div>
    );
};

export default App;
