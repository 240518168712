import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Appview from './Appview';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './settings/store';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#7458da',
        },
      }}
    >
      <Provider store={store}>
        <Appview />
      </Provider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
